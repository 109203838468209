import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout'
import Contact from '../components/contact'
import Projectitems from '../components/projectitems'
import Head from '../components/head'


const ProjectsPage = ({ data }) => {
  const {t} = useTranslation()
  return (
    <Layout>
      <Head title={t('Projects Header')} />
      <h2 className="section-title">
        <Trans>Projects Header</Trans>
      </h2>
      <Projectitems items={data} />
      <h1 className="section-title">
        <Trans>Contact Header</Trans>
      </h1>
      <Contact />
    </Layout>
  )
}

export default ProjectsPage

export const query = graphql `
  query ($language: String!){
    allMarkdownRemark(filter: { frontmatter: { lang: { eq: $language } } },sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            description
            title
            skills
            featuredImage {
              publicURL
            }
            category
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`
