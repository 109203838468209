import React from 'react'

import projectStyles from './project.module.scss'

const getCategories = items => {
    let categoryItems = items.map(item => {
      return item.node.frontmatter.category
    })
    let uniqueCategories = new Set(categoryItems)
    let categories = Array.from(uniqueCategories)
    categories = ["Latest", ...categories]
    return categories
}

const activeButtonClass = {
    backgroundColor: "#747fe0",
    color: "#fff",
  }
class Projectitems extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            items: this.props.items.allMarkdownRemark.edges,
            projectItems: this.props.items.allMarkdownRemark.edges,
            categories: getCategories(this.props.items.allMarkdownRemark.edges),
            selectedItem: getCategories(this.props.items.allMarkdownRemark.edges) && getCategories(this.props.items.allMarkdownRemark.edges)[0],
        }
    }
    handleItems = category => {
        if (category === "Latest") {
          this.setState({
            projectItems: [...this.state.items],
            selectedItem: category,
          })
        } else {
          this.setState({
            projectItems: [
              ...this.state.items.filter(edge => {
                return edge.node.frontmatter.category === category
              }),
            ],
            selectedItem: category,
          })
        }
      }
    render(){
        return(
            <>
                <div className={projectStyles.filterContainer}>
                {this.state.categories.map((category, index) => {
                    return (
                    <button className={projectStyles.filterButton} type="button" key={index} onClick={() => this.handleItems(category)} style={this.state.selectedItem === category ? activeButtonClass : null}>
                        {category}
                    </button>
                    )
                })}
                </div>
                <div className={projectStyles.projectsContainer}>
                 {this.state.projectItems.map((edge, index) => {
                    return (
                <div className={projectStyles.projectCard} key={index}>
                    <img className={projectStyles.projectImage} src={edge.node.frontmatter.featuredImage.publicURL} alt="Project" />
                    <div className={projectStyles.projectBackground} />
                    <div className={projectStyles.projectContent}>
                        <h3>{edge.node.frontmatter.title}</h3>
                        <p>{edge.node.frontmatter.description}</p>
                        <div className={projectStyles.projectSkills}>
                            {
                                edge.node.frontmatter.skills.map((skill, index) => {
                                    return(
                                        <span className={projectStyles.projectSkill} key={index}>{skill}</span>
                                    )
                                })
                            }
                        </div>
                     </div>
                </div>
                    )
                })}
                </div>
            </> 
        )
    }
  }
  
  export default Projectitems

  export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;